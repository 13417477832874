$darkBlue: #1A1735;
$tyrkys: #009AA2;
$greyBG: #F2F1F7;
$greyForm: #E4E6E7;
$fontColorWhite: #ffffff;
$mainFont: "MyOpenSans";
$mainFontSize: 16px;
$h1FontSize: 1.5rem;
$h2Fontsize: 1.3rem;
$h3Fontsize: 1.1rem;
$lineHeight: 1.5;
$navFontSize: 18px;
$navFontSizeSmaller: 16px;
$fontColor: $darkBlue;
$lineHeightP: 1.5;
$aColor: $tyrkys;

///COLORS
$mainBGColor: #338CCD;
$darkBGColor: #006FC1;
$lightBGColor: #B2D4EC;
$activeColor: #56B0F0;
$hoverColorOpaque: rgba(51,140,205, 0.6);


body {
  font-family: $mainFont;
  font-size: $mainFontSize;
  line-height: $lineHeight;
  color: #222222;
}
.pr-0{
    padding-right: 0 !important;
}
.poradci{
    .logo-wrap{
        margin-top: 10px;
    }
    #first-slider{
        .item{
            background-color: $mainBGColor;
        }
    }
    .blog-title,.entry-title,.sidebar-title{
        color:$darkBGColor;
    }
    .category-sidebar{
        li{
            > ul{
                > li{
                    > a{
                        color: $darkBGColor
                    }
                }
            }
        }
    }
    .post-tags{
        a{
            &:hover{
                background: $darkBGColor;
                border-color: $darkBGColor;
            }
        }
    }
    .infolink{
        a{
            color: $darkBGColor;
        }
    }
    .darkbg{
        background: $lightBGColor;
    }
    .search-input-mobile{
        background: $darkBGColor;
    }
    .BGmenu,.menuContainer{
        background: $mainBGColor;
    }
    .menu{
        background: $mainBGColor;
        > ul{
            > li{
                background: $mainBGColor;
                border-image: linear-gradient(to bottom, $mainBGColor, $darkBGColor) 1 100%;
                &:hover{
                    background: $darkBGColor !important;
                }
                &.active{
                    background: $activeColor !important;
                }
                > ul{
                    background-color: $activeColor !important;
                    > li{
                        a{
                            border-bottom: 1px solid $darkBGColor;
                            &:hover{
                                color: $darkBGColor !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .btn-success{
        background-color: $mainBGColor;
        border-color: $mainBGColor;
        &:hover{
            background-color: $darkBGColor;
            border-color: $darkBGColor;
        }
    }
    .blog-news{
            .view1{
                .mask{
                    &:hover{
                        background: $hoverColorOpaque;
                    }
                }
            }
        }
    .box_dluhopisy{
        li{
            a{
                &:hover{
                    color: $darkBGColor;
                }
            }
        }
    }
    .middle-row{
        h2{
            a{
                .fa{
                    color: $darkBGColor;
                }
            }
            .fa{
                color: $darkBGColor;
            }
        }
        h3{
            a{
                .fa{
                    color: $darkBGColor;
                }
            }
            .fa{
                color: $darkBGColor;
            }
        }
    }
    .products-list{
        .sm-items{
            a{
                &:hover{
                    color: $mainBGColor;
                }
            }
        }
    }
    .nav-pills{
        li{
            > a{
                color: $darkBGColor;
            }
            &.active{
                > a{
                    background-color: $darkBGColor;
                    color: $fontColorWhite;
                    &:focus,&:hover{
                        background-color: $darkBGColor;
                        color: $fontColorWhite;
                    }
                }

            }
        }
    }
    .product-info-boxes{
        .box{
            .icon{
                .image{
                    background: $darkBGColor;
                }
            }
        }
    }
    .info-data{
        caption{
            background-color: $lightBGColor;
        }
    }


    //UI Datepicker
    .ui-datepicker-calendar{
        .ui-state-active{
            background: #338CCD;
        }
        a{
            &:hover{
                background: #338CCD;
            }
        }
    }
    .ui-datepicker-calendar{
        th{
            background: $darkBGColor
        }
    }
    .ui-widget-content{
        .ui-state-highlight{
            background-color: $lightBGColor;
            border: 1px solid $activeColor;
        }
    }
    .ui-state-highlight{
        background-color: $lightBGColor;
        border: 1px solid $activeColor;
    }
    .ui-widget-header{
        .ui-state-highlight {
            background-color: $lightBGColor;
            border: 1px solid $activeColor;
        }

    }
    footer{
        a{
            &:hover {
                color: $activeColor;
            }
        }
    }
    .poradci-info{
        font-size: 16px;
        margin-top: 6px;
        font-weight: bold;
        a{
            text-decoration: none;
            font-weight: normal;
        }
    }
    .poradci-icon{
        width: 60px;
    }
}

@media screen and (max-width: 768px) {
    .poradci {
        .poradci-info {
            text-align: center;
            margin: 20px -15px 0;
            background: $darkBGColor;
            padding: 10px 15px;
            color: $fontColorWhite;
            a {
                color: $fontColorWhite;
            }
            .info-items {
                display: inline-block;
                .fa{
                    font-size: 23px;
                }
            }
            .info-tel {
                padding: 0 25px;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 992px){
    .poradci{
        .poradci-info{
            margin: 0;
            background: none;
            color: black;
            padding: 10px 15px;
            font-size: 14px;
            .info-items{
                display: block;
            }
            .info-tel{
                padding: 0;
            }
        }
        #togmc{
            float: right;
        }
    }
}

@media screen and (max-width: 992px) {
    .poradci {
        header{
            padding-bottom: 0;
            & > .container{
                padding-bottom: 0;
            }
        }
        .btn-login{
            display: none;
        }

        .menu{
            > ul{
                > li{
                    a{
                        border-bottom: 1px solid $darkBGColor;
                    }
                    &.login-mobile{
                        a{
                            border: 1px solid $darkBGColor;
                        }
                    }
                }
            }
            .dropdownToggle{
                border-left: 1px solid  $darkBGColor;
            }
        }
        .close_menu{
            background: $darkBGColor;
        }
    }
}

@media screen and (min-width: 992px) {
    .poradci {
        .BGmenu {
            border-bottom: 3px solid $darkBGColor;
        }
    }
}





